import React from "react"
import 'twin.macro'
import { useStaticQuery, graphql } from "gatsby"
import * as moment from 'moment'

function Hours({ day, prefix, holiday }) {
  const isClosed = day === null || day[0] === undefined
  const hasMultipleOpenings = !isClosed && (day.length === 4)
  const defaultFormat = "h:mma"
  if (isClosed) {
    return (
      <span tw="opacity-50">Closed {holiday} {prefix}</span>
    )
  } else if (hasMultipleOpenings){
    const firstOpen = moment.utc(`2019-01-01 ${day[0]}`)
    const firstClose = moment.utc(`2019-01-01 ${day[1]}`)
    const secondOpen = moment.utc(`2019-01-01 ${day[2]}`)
    const secondClose = moment.utc(`2019-01-01 ${day[3]}`)
    return (
      <div>
        <div tw="float-left">{prefix.substring(0, 3)}</div>
        <div tw="margin-left[30px]">
          <div>{moment(firstOpen).format(defaultFormat)} – {moment(firstClose).format(defaultFormat)}</div>
          <div>{moment(secondOpen).format(defaultFormat)} – {moment(secondClose).format(defaultFormat)}</div>
        </div>
      </div>
    )
  } else {
    const opens = moment.utc(`2019-01-01 ${day[0]}`)
    const closes = moment.utc(`2019-01-01 ${day[1]}`)
    return (
      <span>{prefix.substring(0, 3)} {moment(opens).format(defaultFormat)} – {moment(closes).format(defaultFormat)}</span>
    )
  }
}

const TodaysHours = ({ hours }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          holidayFilters
        }
      }
    }
  `)
  const today = new Date()
  const dayOfWeek = today.getDay()
  const holidayHours = data.site.siteMetadata.holidayFilters

  switch (dayOfWeek) {
    case 1:
      return <Hours prefix="Monday" day={hours.mon}/> 
    case 2:
      return holidayHours ? <Hours prefix="Tuesday" holiday="Public Holiday" day={hours.holidays.australia_day}/> : <Hours prefix="Tuesday" day={hours.tue}/> 
    case 3:
      return <Hours prefix="Wednesday" day={hours.wed}/> 
    case 4:
      return <Hours prefix="Thursday" day={hours.thu}/> 
    case 5:
      return <Hours prefix="Friday" day={hours.fri}/> 
    case 6:
      return <Hours prefix="Saturday" day={hours.sat}/> 
    default:  
      return <Hours prefix="Sunday" day={hours.sun}/> 
  }
}

function Location({ postcode, region, street, suburb, title, hours, name, closed }) {
  const label = (title !== null ) ? title : suburb
  return (
    <div
      key={label}
      tw="min-width[240px] flex[0 0 48%]">
      <address
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
        tw="block not-italic text-sm leading-6 h-full">
        <a
          href={`https://www.google.com/maps?q=${encodeURIComponent(`${name} ${street} ${suburb} ${region} ${postcode}`).replace(/%20/g, "+")}`}
          target="_blank"
          rel="noopener noreferrer"
          tw="no-underline h-full py-2 pr-2 mx-2 pl-8 block rounded-md relative text-sm bg-thirdgray-200 text-thirdgray-900 dark:(bg-thirdgray-dark-200 text-thirdgray-dark-900)">
          <svg
            width='10'
            height='14'
            xmlns='http://www.w3.org/2000/svg'
            tw="absolute top[12px] left[10px]">
            <path
              d='M1 10C.11 10-.337 8.923.293 8.293l8-8C8.923-.337 10 .109 10 1v12c0 .961-1.223 1.369-1.8.6L5.5 10H1z'
              fill='currentColor'
              fillRule='nonzero'
              tw="text-thirdgray-900 dark:(text-thirdgray-dark-900)"
            />
          </svg>
          <div tw="mb-2">{label}</div>
          <div itemProp="streetAddress">{street}</div>
          <div><span itemProp="addressLocality">{suburb}</span> <span itemProp="addressRegion">{region}</span> <span itemProp="postalCode">{postcode}</span></div>
          <div tw="mt-2">
            { !closed &&
              <TodaysHours hours={hours} />
            }
          </div>
        </a>
      </address>
    </div>
  )
}

export default Location
