import React from "react"
import 'twin.macro'
import { useStaticQuery, graphql } from "gatsby"
import * as moment from 'moment'

const DayLabel = ({children}) => (
  <dt tw="flex[0 1 38%] text-xs py-2 uppercase tracking-wider line-height[20px]">{children}</dt>
)

const DayDefinition = ({children}) => (
  <dd tw="flex[0 1 62%] m-0 text-sm py-2 text-right line-height[20px]">{children}</dd>
)

const Day = ({children}) => (
  <dl tw="border-solid border-0 border-t border-thirdgray-300 dark:(border-thirdgray-dark-300) m-0 flex">
    {children}
  </dl>
)

const Holiday = ({children}) => (
  <dl tw="border-solid border-0 border-t border-thirdgray-300 dark:(border-thirdgray-dark-300) m-0 flex">
    {children}
  </dl>
)

function Hours({ day }) {
  const isClosed = day === null || day[0] === undefined
  const hasMultipleOpenings = !isClosed && (day.length === 4)
  const defaultFormat = "h:mma"
  if (isClosed) {
    return (
      <span tw="opacity-50">Closed</span>
    )
  } else if (hasMultipleOpenings){
    const firstOpen = moment.utc(`2019-01-01 ${day[0]}`)
    const firstClose = moment.utc(`2019-01-01 ${day[1]}`)
    const secondOpen = moment.utc(`2019-01-01 ${day[2]}`)
    const secondClose = moment.utc(`2019-01-01 ${day[3]}`)
    return (
      <div>
        <div><span>{moment(firstOpen).format(defaultFormat)} – {moment(firstClose).format(defaultFormat)}</span></div>
        <div><span>{moment(secondOpen).format(defaultFormat)} – {moment(secondClose).format(defaultFormat)}</span></div>
      </div>
    )
  } else {
    const opens = moment.utc(`2019-01-01 ${day[0]}`)
    const closes = moment.utc(`2019-01-01 ${day[1]}`)
    return (
      <span>{moment(opens).format(defaultFormat)} – {moment(closes).format(defaultFormat)}</span>
    )
  }
}

const LocationHours = ({ hours, title, suburb }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          holidayFilters
        }
      }
    }
  `)
  const label = (title !== null ) ? title : suburb
  
  const showHolidayHours = data.site.siteMetadata.holidayFilters
  return (
    <React.Fragment>
      <h4 tw="text-base mt-6 mb-2">{label}</h4>
      <div tw="my-2">
        <Day>
          <DayLabel>mon</DayLabel>
          <DayDefinition><Hours day={hours.mon}/></DayDefinition>
        </Day>
        {showHolidayHours ? (
            <Holiday>
              <DayLabel>
                tue
                <div tw="text-thirdgray-500 tracking-wider">public holiday</div>
              </DayLabel>
              <DayDefinition><Hours day={hours.holidays.australia_day}/></DayDefinition>
            </Holiday>
          ) : (
            <Day>
              <DayLabel>tue</DayLabel>
              <DayDefinition><Hours day={hours.tue}/></DayDefinition>
            </Day>
          )}
          <Day>
            <DayLabel>wed</DayLabel>
            <DayDefinition><Hours day={hours.wed}/></DayDefinition>
          </Day>
          <Day>
            <DayLabel>thu</DayLabel>
            <DayDefinition><Hours day={hours.thu}/></DayDefinition>
          </Day>
          <Day>
            <DayLabel>fri</DayLabel>
            <DayDefinition><Hours day={hours.fri}/></DayDefinition>
          </Day>
          <Day>
            <DayLabel>sat</DayLabel>
            <DayDefinition><Hours day={hours.sat}/></DayDefinition>
          </Day>
          <Day>
            <DayLabel>sun</DayLabel>
            <DayDefinition><Hours day={hours.sun}/></DayDefinition>
          </Day>
      </div>
    </React.Fragment>
  )
}

export default LocationHours
