import React from "react"
import 'twin.macro'

function PriceFormatter({amount}) {
  switch (amount) {
    case 2:
      return (
        <span><span>$$</span><span tw="opacity-30">$$</span></span>
      )
    case 3:
      return (
        <span><span>$$$</span><span tw="opacity-30">$</span></span>
      )
    case 4:
      return (
        <span>$$$$</span>
      )
    default:
      return (
        <span><span>$</span><span tw="opacity-30">$$$</span></span>
      )
  }
}

export default PriceFormatter
