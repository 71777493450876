import React from "react"
import 'twin.macro'
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Accent, H2 } from "../components/typography"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Summary from "../components/summary"
import Seo from "../components/seo"

import { LinkButton } from "../components/button"
import Location from "../components/place/location"
import TagLink from "../components/place/tag-link"
import Price from "../components/place/price"
import CollectionCard from "../components/collection/card"

import LocationHours from "../components/place/hours"
import * as Article from '../components/article.module.scss'

const PlaceTemplate = ({ data }) => {
  const place = data.mdx
  const metadata = place.frontmatter
  const isClosed = metadata.closed === true
  const hasArticle = (metadata.article !== null)
  const correctionURL = encodeURI(`https://docs.google.com/forms/d/e/1FAIpQLSf6m_QG8PrqIGgNeWG-ZhI12wMrciGnhWevBLvivEOiYu50aQ/viewform?usp=pp_url&entry.1995491423=${metadata.name}`)
  const inCollections = (data.allMdx.edges.length > 0)

  const PlaceMetadata = ({ closed, speciality, price }) => {
    if (closed) {
      return (
        <Accent>
          Closed
        </Accent>
      )
    } else {
      return (
        <Accent>
          {speciality} · <Price amount={price}/>
        </Accent>
      )
    }
  }

  return (
    <Layout
      styleContext="feature">
      <Seo
        title={!isClosed ? metadata.name : `CLOSED: ${metadata.name}`}
        pathname={place.fields.slug}
        image={`${place.fields.slug.substring(0, place.fields.slug.length - 1)}.jpg`}
        description={!isClosed ? metadata.summary.substring(0, 150) : `${metadata.name} has permanently closed. ${metadata.summary.substring(0, 90)}`}
      />
      <div tw="grid grid-template-rows[auto auto auto auto] grid-template-areas['hero' 'side' 'main' 'aside'] md:(gap-5) lg:(grid-template-columns[0.1fr 1fr 0.1fr 180px 0.1fr] grid-template-rows[1fr auto] grid-template-areas['hero hero side side side' '. main aside aside .'] grid-gap[32px]) 2xl:(grid-template-columns[1fr 2.8fr 1fr 1fr] grid-template-rows[1fr auto] grid-template-areas['hero hero side side' '. main aside .'] grid-gap[40px])">
        <div tw="grid-area[side] overflow-hidden pt-6 px-6 lg:(px-0 py-12 pr-6)">
          <div tw="relative">
            <PlaceMetadata closed={isClosed} speciality={metadata.speciality} price={metadata.price}/>
            <h1 tw="text-2xl font-serif leading-normal my-2 text-thirdgray-900 md:(text-4xl) lg:(text-5xl my-6) xl:(text-6xl leading-normal) dark:(text-thirdgray-dark-900)">{metadata.name}</h1>
          </div>
          <div tw="flex -mx-2 pb-4 relative rounded overscroll-x-auto overflow-x-scroll" >
            {metadata.locations.map(({ title, postcode, region, street, suburb, hours }) => (
              <Location key={`${region}-${postcode}-${street}`} name={metadata.name} title={title} postcode={postcode} region={region} street={street} suburb={suburb} hours={hours} closed={isClosed} />
            ))}
          </div>
          <div>
            {metadata.tags.map(tag => (
              <TagLink
                tag={tag}
                key={tag}
              />
            ))}
          </div>
        </div>
        <div tw='relative overflow-hidden grid-area[hero] justify-self-stretch self-stretch min-height[90vw] md:(min-height[38vw])'>
          {isClosed && <div sx={{position: `absolute`, fontWeight: 600, fontSize: [0, 1, 1], bottom: 0, right: 0, py: 3, px: `24px`, backgroundColor: `status.closed`, color: `#fff`, zIndex: 100000 }}>Permanently Closed</div>}
          <Img fluid={data.file.childImageSharp.fluid} alt={metadata.name} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: isClosed ? 0.66 : 1 }} imgStyle={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}/>
        </div>
        <div tw="grid-area[main] text-thirdgray-900 font-serif text-lg lg:(px-6) dark:(text-thirdgray-dark-900) md:(py-8 text-xl) lg:(py-12) 2xl:(py-14)">
          <p tw="px-6 italic leading-loose mt-6 mb-3 text-2xl md:(text-3xl mb-9) lg:(mt-0 px-0 text-3xl)">{metadata.summary}</p>
          <div className={`${Article.place} ${Article.typography}`}>
            <MDXRenderer>{place.body}</MDXRenderer>
          </div>
          {inCollections &&
            <div tw="py-6">
              <H2 tw="mb-4">Featured in Collections</H2>
              <div tw="grid grid-cols-2 grid-template-rows[44vh] grid-auto-rows[44vh] gap-2">
                {data.allMdx.edges.map(({ node }) => (
                  <CollectionCard authors={node.frontmatter.authors} title={node.frontmatter.title} key={node.fields.slug} path={node.fields.slug} summary={node.frontmatter.summary} places={node.frontmatter.places} sourceDirectory={node.fields.sourceDirectory} />
                ))}
              </div>
            </div>
          }
        </div>
        <div tw="grid-area[aside] px-6 pb-8 lg:(px-0)">
          {!isClosed && metadata.locations.map(({ title, suburb, hours, street }) => (
            <LocationHours key={`${suburb}-${street}`} title={title} suburb={suburb} hours={hours}/>
          ))}
          {hasArticle &&
            <a
              href={metadata.article.url}
              target="_blank"
              rel="noopener noreferrer"
              tw="block rounded-md p-3 bg-thirdgray-200 mb-4 dark:(bg-thirdgray-dark-200)">
              <div tw="text-xs text-thirdgray-500 dark:(text-thirdgray-dark-500)">Down by 12th Feature</div>
              <h3 tw="text-sm font-semibold text-thirdgray-900 dark:(text-thirdgray-dark-900)">
                {metadata.article.title}
              </h3>
            </a>
          }
          <LinkButton href={correctionURL} target="_blank" rel="noopener noreferrer">Submit correction</LinkButton>
        </div>
      </div>
      <Summary/>
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $filename: String!) {
    mdx(fields: {slug: {eq: $slug}, sourceName: {eq: "places"}}) {
      fields {
        slug
      }
      frontmatter {
        category
        locations {
          title
          hours {
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            holidays {
              australia_day
              labour_day
            }
          }
          postcode
          region
          street
          suburb
        }
        name
        speciality
        summary
        tags
        title
        price
        closed
        article {
          title
          url
        }
      }
      body
    }
    file(relativeDirectory: {eq: $filename}, name: {eq: "hero"}) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(filter: {fields: {sourceName: {eq: "collections"}}, frontmatter: {draft: {eq: false}, places: {in: [ $filename ] }}}) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            places
            authors {
              name
              path
            }
          }
          fields {
            slug
            sourceDirectory
          }
        }
      }
    }
  }
`

export default PlaceTemplate
